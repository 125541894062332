/* eslint-disable react-hooks/exhaustive-deps */
import {
  PageTitle,
} from "../../../_start/layout/core";
import { OrderPage } from "./OrderPage";
import { useHistory, useParams } from "react-router-dom";
import { OrderKind, OrderSelector } from "../../types/orderSelector";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../setup";
import { useEffect, useState } from "react";
import { getActiveOrder, getTableActiveOrder } from "../../services/api";
import { OrderInfo, OrderStatus } from "../../types/order";

export function OrderWrapper() {
  const { tableId, orderId } = useParams<{ tableId: string; orderId: string }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [order, setOrder] = useState<OrderInfo>();
  const [orderType, setOrderType] = useState<OrderSelector>({orderId: orderId ?? '', tableId: tableId ?? '', kind: tableId ? OrderKind.Table : OrderKind.TakeAway});

  const history = useHistory();

  const auth = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  );

  useEffect(() => {
    let isMounted = true;
    const orderLike = {orderId: orderId ?? '', tableId: tableId ?? '', kind: tableId ? OrderKind.Table : OrderKind.TakeAway};
    setOrderType(orderLike);
    setLoading(true);

    if ((orderLike.orderId.toString() === 'create' || orderLike.kind === OrderKind.TakeAway) && (auth as any).type === 'attendant') {
      history.goBack();
    }

    const getOrder = async (orderLike: any) => {
      let order = null;
      if (orderLike.kind === OrderKind.Table && orderLike.tableId) {
        order = await getTableActiveOrder(orderLike.tableId.toString());
        orderLike.orderId = order.id;
        orderType.orderId = (order.id ?? '').toString();
        setOrderType(orderType);
      } else if (orderLike.kind === OrderKind.TakeAway) {
        order = await getActiveOrder(orderLike.orderId.toString());
      }
      
      if (isMounted) {
        setOrder(order);
        setLoading(false);
      }
    }

    if (orderLike.orderId.toString() === 'create' ) {
      const emptyOrder: OrderInfo = {
        id: 0,
        items: {},
        amount: {},
        created_at: '',
        opening_user_name: '',
        status: OrderStatus.Active,
        closing_user_name: undefined,
        updated_at: undefined,
        table: undefined
      };
      setOrder(emptyOrder);
      setLoading(false);
    } else {
      setOrder(undefined);
      try {
        getOrder(orderLike);    
      } catch (error) {
        //
      }
    }

    return () => {
      isMounted = false;
    };
  }, [orderId]);

  return (
    <>
      <PageTitle>Hesap</PageTitle>
      { loading ? (
        <div className="row g-0 g-xl-5 g-xxl-8" style={{justifyContent: 'center', alignContent: 'center', height: '100%'}}>
          <span
            className="spinner-border text-primary ms-3 mt-6"
            role="status">
            <span className="visually-hidden">Yükleniyor...</span>
          </span>
        </div>
        ): (
        order && 
          <OrderPage orderLike={orderType} orderInfo={order}/>
        )
      }
    </>
  );
}
